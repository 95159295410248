// @ts-strict-ignore
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FilePreviewDialogComponent } from '../file-preview/file-preview-dialog/file-preview-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Attachment } from '@app/core/types/attachment';
import { Pagination, Sorting } from '@app/core/types/common';
import { AttachmentType, MessageAttachment } from '@app/core/types/attachment';
import { ConfirmDialogComponent } from '@app/shared/components/confirm-dialog/confirm-dialog.component';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, of, Subject } from 'rxjs';
import { CurrentUserService } from '@core-services';
import { User } from '@app/core/types/user';
import { ContractFile, Quotation, QuotationStatus } from '@app/core/types/quotation';
import moment from 'moment';
import { Sort } from '@angular/material/sort';

type AttachmentListItem = {
  attachment: Attachment;
  typePrivateFile?: FileDownloadFileType;
  objectId?: string;
  url?: string;
  client?: string;
  airline?: string;
};

export enum FileDownloadFileType {
  CONTRACT_TERMS = 'contract-terms',
  ATTACHMENT = 'attachment',
  PAX_LIST = 'pax-list',
  MESSAGES = 'messages',
  PAX_LIST_TEMPLATE = 'pax-list-template',
  INVOICES = 'invoices',
  AIRLINE_ATTACHMENT = 'airline-attachment',
}

@Component({
  selector: 'attachment-list',
  templateUrl: './attachment-list.component.html',
  styleUrls: ['./attachment-list.component.scss'],
})
export class AttachmentListComponent implements OnInit, OnChanges {
  @Input()
  contractTerms: Attachment[] = [];
  @Input() attachments: Attachment[] = [];
  @Input() termsAndConditions: ContractFile[] = [];
  @Input() messageAttachments: MessageAttachment[] = [];
  @Input() paxLists: Attachment[] = [];
  @Input() invoices: Attachment[] = [];
  @Input() quotation: Quotation | undefined = undefined;
  @Output() onDeleteAttachment: EventEmitter<{ attachment: Attachment; objectId: string }> =
    new EventEmitter<{ attachment: Attachment; objectId: string }>();

  user: User;
  private _unsubscribeAll: Subject<void> = new Subject();

  attachmentColumns = ['originalFilename', 'attachmentType', 'uploadedAt', 'actions'];
  attachmentList: AttachmentListItem[] = [];
  displayedAttachments: AttachmentListItem[] = [];

  private pagination = new BehaviorSubject<Pagination>({ pageIndex: 0, pageSize: 10 });
  private sorting = new BehaviorSubject<Sorting | undefined>({
    sortBy: 'uploadedAt',
    sortDir: 'desc',
  });
  private filtering = new BehaviorSubject<string>('');

  constructor(
    private _matDialog: MatDialog,
    private _currentUserService: CurrentUserService,
  ) {}

  ngOnInit() {
    this.user = this._currentUserService.getUser();
  }

  ngOnChanges(_changes: SimpleChanges): void {
    this.attachmentList = this.getAttachmentList();
    this.refreshAttachments();
  }

  get currentPagination(): Pagination {
    return this.pagination.value;
  }

  get currentSorting(): Sorting {
    return this.sorting.value;
  }

  changePaging({ pageIndex, pageSize }: Pagination): void {
    this.pagination.next({
      pageIndex,
      pageSize,
    });
  }

  resetPagination() {
    this.pagination.next({ pageIndex: 0, pageSize: 10 });
  }

  changeSorting(sort: Sort): void {
    this.resetPagination();
    const noSorting = !sort.active || sort.direction === '';
    this.sorting.next(
      noSorting
        ? { sortBy: 'uploadedAt', sortDir: 'desc' }
        : { sortBy: sort.active, sortDir: sort.direction },
    );
  }

  changeFiltering(selection: any) {
    this.resetPagination();
    this.filtering.next(selection.value);
  }

  refreshAttachments() {
    combineLatest([this.pagination, this.sorting, this.filtering])
      .pipe(
        takeUntil(this._unsubscribeAll),
        //-- switchmap is map that cancels previous when new event comes in
        switchMap(() =>
          //-- refresh the attachment list paging and sorting here
          of(this.getDisplayedAttachments()),
        ),
      )
      .subscribe((data) => {
        this.displayedAttachments = data;
        this.attachmentList = this.getFilteredAttachments();
      });
  }

  getFilteredAttachments() {
    return this.getAttachmentList().filter((attachment) => {
      if (
        this.filtering.value === '' ||
        this.filtering.value === undefined ||
        this.filtering.value === 'all'
      ) {
        return true;
      }
      return attachment.attachment.attachmentType === this.filtering.value;
    });
  }

  getDisplayedAttachments(): AttachmentListItem[] {
    return this.getFilteredAttachments()
      .sort((a, b) => this.sortAttachments(a, b))
      .slice(
        this.currentPagination.pageIndex * this.currentPagination.pageSize,
        (this.currentPagination.pageIndex + 1) * this.currentPagination.pageSize,
      );
  }

  private sortAttachments(a: AttachmentListItem, b: AttachmentListItem): number {
    const attachmentA = a.attachment;
    const attachmentB = b.attachment;
    if (this.currentSorting.sortDir === 'asc') {
      return this.compareParams(
        attachmentA[this.currentSorting.sortBy],
        attachmentB[this.currentSorting.sortBy],
      );
    } else {
      return this.compareParams(
        attachmentB[this.currentSorting.sortBy],
        attachmentA[this.currentSorting.sortBy],
      );
    }
  }

  private compareParams(a: any, b: any): number {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  }

  isOrder(): boolean {
    return !!this.quotation.confirmedByAirline;
  }

  isAttachmentDeletable(attachment: AttachmentListItem): boolean {
    if (
      [AttachmentType.EXTERNAL_URL, AttachmentType.PAX_LIST_TEMPLATE].includes(
        attachment.attachment.attachmentType,
      )
    ) {
      //-- external urls cannot be deleted from the list
      return false;
    }
    if (this.user.root) {
      //-- root can delete anything else
      return true;
    }
    if (AttachmentType.MESSAGE === attachment.attachment.attachmentType) {
      //-- only same organization users can delete message attachments
      return (
        (this.user.client && this.user.client._id === attachment.client) ||
        (this.user.airline && this.user.airline._id === attachment.airline)
      );
    }
    if (
      this.user.airline &&
      AttachmentType.CONTRACT == attachment.attachment.attachmentType &&
      (this.isOrder() || [QuotationStatus.OFFER_CLIENT_CONFIRMED].includes(this.quotation.status))
    ) {
      //-- airline can only delete contracts if the offer has not been confirmed
      //-- or airline has added them after the confirmation
      if (this.isOrder()) {
        //-- for orders they can delete if uploaded after order was created
        return moment(attachment.attachment.uploadedAt).isAfter(
          moment(this.quotation.confirmedByAirline),
        );
      } else {
        //-- for quotations delete if uploaded after client confirmation
        return moment(attachment.attachment.uploadedAt).isAfter(
          moment(this.quotation.confirmedByClient),
        );
      }
    }
    if (
      this.user.client &&
      [AttachmentType.PAX_LIST].includes(attachment.attachment.attachmentType)
    ) {
      //-- clients can delete their own uploaded pax lists
      return true;
    }
    //-- otherwise airline can delete them if they can see them, clients not so much
    return !!this.user.airline;
  }

  isAttachmentSentToClient(row: AttachmentListItem): boolean {
    switch (row.attachment.attachmentType) {
      case AttachmentType.CONTRACT:
        return !!this.quotation.contractSentAt;
      case AttachmentType.ATTACHMENT:
        return !!this.quotation.offerSentAt;
      case AttachmentType.PAX_LIST_TEMPLATE:
        return !!this.quotation.confirmedByAirline;
      case AttachmentType.EXTERNAL_URL:
      case AttachmentType.INVOICE:
      case AttachmentType.MESSAGE:
      case AttachmentType.PAX_LIST:
        return true;
    }
    //-- what type is this?
    return true;
  }

  openAttachment(row: AttachmentListItem) {
    if (row.url) {
      window.open(row.url, '_blank');
    } else {
      const data = {
        attachment: row.attachment,
        objectId: row.objectId,
        typePrivateFile: row.typePrivateFile,
        dialog: true,
      };
      this._matDialog.open(FilePreviewDialogComponent, {
        panelClass: 'contract-terms-dialog-attachment',
        data,
      });
    }
  }

  deleteAttachmentItem(row: AttachmentListItem) {
    const confirmDialogRef = this._matDialog.open(ConfirmDialogComponent, {
      width: '380px',
      data: {
        messageKey: 'attachmentList.deleteConfirm',
        actionKey: ' ',
        confirmBtnKey: 'forms.confirmBtn',
        cancelBtnKey: 'forms.cancelBtn',
        showConfirmCheck: true,
      },
    });

    confirmDialogRef
      .afterClosed()
      .pipe(
        takeUntil(this._unsubscribeAll),
        filter((result) => result === 'Confirm'),
      )
      .subscribe(() => {
        this.onDeleteAttachment.next({ attachment: row.attachment, objectId: row.objectId });
      });
  }

  private getAttachmentList(): AttachmentListItem[] {
    const contractTermsItems = this.quotation
      ? this.contractTerms.map((attachment) => ({
          attachment,
          typePrivateFile: FileDownloadFileType.CONTRACT_TERMS,
          objectId: this.quotation._id,
        }))
      : [];
    const attachmentItems = this.quotation
      ? this.attachments.map((attachment) => ({
          attachment,
          typePrivateFile: FileDownloadFileType.ATTACHMENT,
          objectId: this.quotation._id,
        }))
      : [];
    const termsAndConditionsItems = this.termsAndConditions.map((tc) => ({
      typePrivateFile: FileDownloadFileType.CONTRACT_TERMS,
      attachment: { originalFilename: tc.name, attachmentType: AttachmentType.EXTERNAL_URL },
      url: tc.url,
    }));
    const paxListItems = this.isOrder()
      ? this.paxLists.map((attachment) => ({
          attachment,
          typePrivateFile: FileDownloadFileType.PAX_LIST,
          objectId: this.quotation._id,
        }))
      : [];
    const invoiceItems = this.isOrder()
      ? this.invoices.map((attachment) => ({
          attachment,
          typePrivateFile: FileDownloadFileType.INVOICES,
          objectId: this.quotation._id,
        }))
      : [];
    const messageItems = this.messageAttachments.map((attachment) => ({
      attachment: attachment.attachment,
      typePrivateFile: FileDownloadFileType.MESSAGES,
      objectId: attachment.messageId,
      airline: attachment.airline,
      client: attachment.client,
    }));
    const paxListTemplates = this.quotation.airline.paxListTemplates.map((attachment) => ({
      attachment: attachment,
      typePrivateFile: FileDownloadFileType.PAX_LIST_TEMPLATE,
      objectId: this.quotation.airline._id,
    }));
    let result = [
      ...paxListItems,
      ...invoiceItems,
      ...contractTermsItems,
      ...attachmentItems,
      ...termsAndConditionsItems,
      ...messageItems,
    ];
    //-- other fields only come from api if they're sent to client, pax template is always on airline object
    //-- but should only be shown to client if this is an order
    if (
      paxListTemplates.length > 0 &&
      (!this.user?.client || this.isAttachmentSentToClient(paxListTemplates[0]))
    ) {
      result = result.concat(paxListTemplates);
    }
    return result;
  }

  getListTypes() {
    return [
      'all',
      ...new Set(this.getAttachmentList().map((item) => item.attachment.attachmentType)),
    ];
  }

  public getNotSentTooltip(row: AttachmentListItem) {
    if (row.attachment.attachmentType === AttachmentType.PAX_LIST_TEMPLATE) {
      //-- pax templates follow their own rules
      if (!this.isAttachmentSentToClient(row))
        return 'attachmentList.notSent.tooltip.paxListTemplate';
    } else {
      //-- other attachments will be sent based on status
      if (this.quotation.status === QuotationStatus.WAITING_FOR_OFFER) {
        return 'attachmentList.notSent.tooltip.waitingForOffer';
      }
      if (this.quotation.status === QuotationStatus.OFFER_CONTRACT_REQUESTED) {
        return 'attachmentList.notSent.tooltip.contractRequested';
      }
    }
    return '';
  }

  protected readonly AttachmentType = AttachmentType;
}
