import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { AircraftType, Pagination, Sorting } from '@app/core/types/common';
import { ApiService } from '@app/core/services';
import { map, switchMap } from 'rxjs/operators';
import { AirplaneListFilter, AirplaneListItem, AirplaneToUpdate } from '@app/core/types/airplane';

const DEFAULT_FILTER: AirplaneListFilter = {};

@Injectable()
export class AirplanePoolService {
  searchText = new BehaviorSubject<string>('');
  pagination = new BehaviorSubject<Pagination>({ pageSize: 100, pageIndex: 0 });
  forceUpdateAirplanes = new BehaviorSubject<boolean>(true);
  sortBy = new BehaviorSubject<Sorting | undefined>(undefined);
  filterBy = new BehaviorSubject<AirplaneListFilter>(DEFAULT_FILTER);

  constructor(private _apiService: ApiService) {}

  setAircraftTypeFilter(aircraftType?: AircraftType) {
    this.filterBy.next({
      ...this.filterBy.value,
      aircraftType: aircraftType,
    });
  }

  getAirplanes(): Observable<{ airplanes: AirplaneListItem[]; total: number }> {
    return combineLatest([
      this.pagination,
      this.searchText,
      this.filterBy,
      this.sortBy,
      this.forceUpdateAirplanes,
    ]).pipe(
      switchMap(() =>
        this._apiService.getAirplaneList(
          this.searchText.value,
          this.filterBy.value,
          {
            pageSize: this.pagination.value.pageSize,
            page: this.pagination.value.pageIndex + 1,
          },
          this.sortBy.value,
        ),
      ),
    );
  }

  /* TODO: Unused & unworking availability stuff.
  getBusyTimesByAirplane(resetPage = true, airplaneId: string): Promise<any> {
    if (resetPage) {
      this.pagination.next({ ...this.pagination.value, pageIndex: 0 });
    }

    const flightsConfirmed: FlightConfirmed[] = [];
    const { pageSize, pageIndex } = this.pagination.value;
    return new Promise(async (resolve, reject) => {
      await this._apiService
        .getBusyTimesByAirplane({ airplane: airplaneId }, { pageSize, page: pageIndex + 1 })
        .subscribe((response: any) => {
          let scheduleConfirmed: FlightConfirmed;

          response.airplaneBusyTimes.forEach((orderConfirmed: any) => {
            scheduleConfirmed = {
              description: orderConfirmed.description,
              startTime: moment(orderConfirmed.departureDate).clone(),
              endTime: moment(orderConfirmed.departureDate)
                .clone()
                .add(parseFloat(orderConfirmed.duration) * 60, 'minutes'),
              returnStartTime: moment(orderConfirmed.returnDate).clone(),
              returnEndTime: moment(orderConfirmed.returnDate)
                .clone()
                .add(parseFloat(orderConfirmed.duration) * 60, 'minutes'),
              duration: parseFloat(orderConfirmed.duration) * 60,
              returnDuration: parseFloat(orderConfirmed.duration) * 60,
              passengers: orderConfirmed.passengers,
              capacitySeats: orderConfirmed.capacitySeats,
              origin: orderConfirmed.fromAirport,
              destination: orderConfirmed.toAirport,
              timezoneOrigin: orderConfirmed.timezoneOrigin,
              timezoneDestination: orderConfirmed.timezoneDestination,
            };
            flightsConfirmed.push(scheduleConfirmed);
          });
          resolve(flightsConfirmed);
        }, reject);
    });
  }

  getBusyTimesAirplanesByAirline(resetPage = true, fromDate: any, toDate: any): Promise<any> {
    if (resetPage) {
      this.pagination.next({ ...this.pagination.value, pageIndex: 0 });
    }
    const { pageSize, pageIndex } = this.pagination.value;
    return new Promise(async (resolve, reject) => {
      await this._apiService
        .getBusyTimesAirplanesByAirline(
          { fromDate: fromDate, toDate: toDate },
          { pageSize, page: pageIndex + 1 },
        )
        .subscribe((response) => {
          let scheduleConfirmed: FlightConfirmed;
          let airplanesScheduleConfirmed: any = [];

          response.timesBusyAirplanesByAirline.forEach((airplane: any) => {
            let arrayScheduleAirplane: any[] = [];
            if (airplane.orders.length > 0) {
              airplane.orders.forEach((orderConfirmed: any) => {
                scheduleConfirmed = {
                  description: airplane.aircraft.name,
                  startTime: moment(orderConfirmed.departureDate).clone(),
                  endTime: moment(orderConfirmed.departureDate)
                    .clone()
                    .add(parseFloat(orderConfirmed.duration) * 60, 'minutes'),
                  returnStartTime: moment(orderConfirmed.returnDate).clone(),
                  returnEndTime: moment(orderConfirmed.returnDate)
                    .clone()
                    .add(parseFloat(orderConfirmed.duration) * 60, 'minutes'),
                  duration: parseFloat(orderConfirmed.duration) * 60,
                  returnDuration: parseFloat(orderConfirmed.duration) * 60,
                  passengers: orderConfirmed.passengers,
                  capacitySeats: orderConfirmed.capacitySeats,
                  origin: orderConfirmed.fromAirport,
                  destination: orderConfirmed.toAirport,
                  timezoneOrigin: orderConfirmed.timezoneOrigin,
                  timezoneDestination: orderConfirmed.timezoneDestination,
                };
                arrayScheduleAirplane.push(scheduleConfirmed);
              });
            }
            airplanesScheduleConfirmed.push({ ...airplane, busyTimes: arrayScheduleAirplane });
          });
          resolve(airplanesScheduleConfirmed);
        }, reject);
    });
  }
  */
  createAirplane(airplane: AirplaneToUpdate): Observable<void> {
    return this._apiService.createAirplane(airplane).pipe(map(() => undefined));
  }
  updateAirplane(id: string, airplane: AirplaneToUpdate): Observable<void> {
    return this._apiService.updateAirplane(id, airplane).pipe(map(() => undefined));
  }
  deleteAirplane(id: string): Observable<void> {
    return this._apiService.deleteAirplane(id).pipe(map(() => undefined));
  }
}
