// @ts-strict-ignore
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable, Subject, timer } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { navigation } from 'app/navigation/navigation';
import { User } from '@app/core/types/user';
import { MatDialog } from '@angular/material/dialog';
import { WelcomeDialogComponent } from '@app/shared/components/welcome-dialog/welcome-dialog.component';
import {
  ApiService,
  AuthenticationService,
  CurrentUserService,
  UnsavedChangesService,
} from '@app/core/services';
import { NotificationMessage } from '@app/core/types/message';

@Component({
  selector: 'toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ToolbarComponent implements OnInit, OnDestroy {
  horizontalNavbar: boolean;
  rightNavbar: boolean;
  hiddenNavbar: boolean;
  navigation: any;
  userStatusOptions: any[];
  user: User;
  isLoggedIn$: Observable<boolean>;
  newMessages: NotificationMessage[] = [];
  newMessagesNumber = null;
  loginMessageSeenTime: Date;
  previousTimeSeen: Date;
  firstMessageCheck = true;
  notificationsUpdatedAt?: string = undefined;

  // Private
  private _unsubscribeAll: Subject<void>;

  constructor(
    private _fuseConfigService: FuseConfigService,
    private _fuseSidebarService: FuseSidebarService,
    private _matDialog: MatDialog,
    private authenticationService: AuthenticationService,
    private currentUserService: CurrentUserService,
    private apiService: ApiService,
    private router: Router,
    private unsavedChangesService: UnsavedChangesService,
  ) {
    // Set the defaults
    this.userStatusOptions = [
      {
        title: 'Online',
        icon: 'icon-checkbox-marked-circle',
        color: '#4CAF50',
      },
      {
        title: 'Away',
        icon: 'icon-clock',
        color: '#FFC107',
      },
      {
        title: 'Do not Disturb',
        icon: 'icon-minus-circle',
        color: '#F44336',
      },
      {
        title: 'Invisible',
        icon: 'icon-checkbox-blank-circle-outline',
        color: '#BDBDBD',
      },
      {
        title: 'Offline',
        icon: 'icon-checkbox-blank-circle-outline',
        color: '#616161',
      },
    ];

    this.navigation = navigation;

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.isLoggedIn$ = this.currentUserService.isLoggedIn;
    this.getUserData();
    this.getMessageData();

    // Subscribe to the config changes
    this._fuseConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe((settings) => {
      this.horizontalNavbar = settings.layout.navbar.position === 'top';
      this.rightNavbar = settings.layout.navbar.position === 'right';
      this.hiddenNavbar = settings.layout.navbar.hidden === true;
    });

    const user = this.currentUserService.getUser();
    if (!user.root && this.currentUserService.getFirstLogin()) {
      let maxHeight = '50em';
      let width = '70%';
      if (user.client) maxHeight = '30em';
      // '< 959' = lt-md
      if (window.innerWidth < 959) {
        maxHeight = '75vh';
        width = '95%';
      }

      this._matDialog
        .open(WelcomeDialogComponent, {
          height: '90%',
          width: width,
          maxHeight: maxHeight,
        })
        .afterClosed()
        .subscribe(
          () => {
            this.currentUserService.setFirstLogin(false);
          },
          (err) => {
            console.error(err);
          },
        );
    }
  }

  getUserData(): void {
    this.currentUserService
      .currentUser()
      .pipe(
        filter((value) => value !== null && value._id !== undefined),
        takeUntil(this._unsubscribeAll),
      )
      .subscribe((user: User) => {
        this.user = user;
        if (!this.loginMessageSeenTime) {
          this.loginMessageSeenTime = user.newMessagesSeenTime;
        }
      });
  }

  getMessageData(): void {
    timer(0, 30000)
      .pipe(
        switchMap(() => this.apiService.getNotificationMessages(this.notificationsUpdatedAt)),
        takeUntil(this._unsubscribeAll),
      )
      .subscribe({
        next: (res: any) => {
          if (!res.updatedAtMatches) {
            if (res.messages) {
              res.messages = res.messages || [];
              this.notificationsUpdatedAt = res.updatedAt;
              if (
                this.firstMessageCheck === false &&
                res.count > 0 &&
                this.newMessages[0]?.createdAt !== res.messages[0]?.createdAt
              ) {
                void this.playPing();
              }
              this.firstMessageCheck = false;
              this.newMessages = res.messages;
              this.newMessagesNumber = res.count || null;
            }
          }
        },
        error: (error: any) => {
          console.error(error);
        },
      });
  }

  updateNewMessagesSeen(): void {
    if (this.user.newMessagesSeenTime) {
      this.previousTimeSeen = this.user.newMessagesSeenTime;
    }
    this.currentUserService.updateUser(this.user);
    this.apiService
      .updateUser(this.user._id, { newMessagesSeenTime: new Date(Date.now()) })
      .toPromise()
      .then((user) => {
        this.newMessagesNumber = null;
        this.user = user;
      });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  toggleSidebarOpen(key): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }

  signOut(): void {
    this.router.navigate(['/auth/logout']);
  }

  redirectToQuotation(message: NotificationMessage): void {
    /** This is required for route reloading **/
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    if (message.quotation.status.indexOf('order') >= 0) {
      this.router.navigate(['./list/order/' + message.quotation.referenceNumber + '/']);
    } else {
      this.router.navigate(['./list/offer/' + message.quotation.referenceNumber + '/']);
    }
  }

  getNotificationHeader(message) {
    let header = message.content.split('\n')[0];
    if (!!this.currentUserService.getUser().client) {
      if (header === 'CONTRACT SENT') header = 'CONTRACT RECEIVED';
    }
    return header;
  }

  async playPing(): Promise<void> {
    try {
      const audio = new Audio();
      audio.src = '../../../../assets/sounds/ping.mp3';
      audio.load();
      await audio.play();
    } catch (err) {
      if (err.name !== 'NotAllowedError') {
        // If error is "NotAllowedError", the browser has been blocked the sound. No need log that error.
        console.error('Failed to play ping sound', err);
      }
    }
  }
}
