// @ts-strict-ignore
import {
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  AfterViewInit,
  ViewChild,
  ViewEncapsulation,
  ChangeDetectorRef,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject, timer } from 'rxjs';
import { delay, filter, switchMap, take, takeUntil } from 'rxjs/operators';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { AuthenticationService, CurrentUserService } from '@app/core/services';
import { AppSettingsConfig } from '@app/core/configs/app-settings.config';
import { Enquiry } from '@app/core/types/enquiry';
import { User } from '@app/core/types/user';
import { FuseSidebarComponent } from '@fuse/components/sidebar/sidebar.component';
import { ApiService } from '@app/core/services/api.service';
import { fuseAnimations } from '../../../../../../@fuse/animations';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'navbar-vertical-style-1',
  templateUrl: './style-1.component.html',
  styleUrls: ['./style-1.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    fuseAnimations,
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', fontSize: '0' })),
      state('expanded', style({ height: '*', fontSize: '14px' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class NavbarVerticalStyle1Component implements OnInit, OnDestroy, AfterViewInit {
  fuseConfig: any;
  navigation: any;
  user: User;
  enquiry: Enquiry;
  features: any;
  navbar: FuseSidebarComponent;

  menuMode = false;

  hideItemsAirlineRole = ['enquiries_create', 'users_create'];
  hideItemsClientRole = ['quotations_create', 'users_create'];
  // Private
  private _fusePerfectScrollbar: FusePerfectScrollbarDirective;
  private _unsubscribeAll: Subject<void>;

  /**
   * Constructor
   *
   * @param {FuseConfigService} _fuseConfigService
   * @param {FuseNavigationService} _fuseNavigationService
   * @param {FuseSidebarService} _fuseSidebarService
   * @param {Router} _router
   * @param currentUserService
   * @param authenticationService
   * @param appConfig
   * @param router
   * @param apiService
   */
  constructor(
    private _fuseConfigService: FuseConfigService,
    private _fuseNavigationService: FuseNavigationService,
    private _fuseSidebarService: FuseSidebarService,
    private _router: Router,
    private _changeDetectorRef: ChangeDetectorRef,
    private currentUserService: CurrentUserService,
    private authenticationService: AuthenticationService,
    public appConfig: AppSettingsConfig,
    private router: Router,
    private apiService: ApiService,
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
    // this.features = this.appConfig.operator.features;
    this.features = [];
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  // Directive
  @ViewChild(FusePerfectScrollbarDirective, { static: true })
  set directive(theDirective: FusePerfectScrollbarDirective) {
    if (!theDirective) {
      return;
    }

    this._fusePerfectScrollbar = theDirective;

    // Update the scrollbar on collapsable item toggle
    this._fuseNavigationService.onItemCollapseToggled
      .pipe(delay(500), takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this._fusePerfectScrollbar.update();
      });

    // Scroll to the active item position
    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        take(1),
      )
      .subscribe(() => {
        setTimeout(() => {
          this._fusePerfectScrollbar.scrollToElement('navbar .nav-link.active', -200);
        });
      });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.updateMenuVisibility(event);
  }

  updateMenuVisibility(event): void {
    // The sidebar is thinner between 1200px and 1400px screen widths
    this.menuMode = false;
    if (this.isTouchEnabled()) {
      if (event.target.innerWidth < 1400) {
        this._fuseSidebarService.getSidebar('navbar').close(true);
        this.menuMode = true;
      } else {
        this._fuseSidebarService.getSidebar('navbar').open(true);
      }
    } else {
      if (window.innerWidth >= 1280 && window.innerWidth <= 1400) {
        this._fuseSidebarService.getSidebar('navbar').fold();
      } else {
        this._fuseSidebarService.getSidebar('navbar').unfold();
        if (window.innerWidth < 1280) this.menuMode = true;
      }
      this._changeDetectorRef.markForCheck();
    }
  }

  isTouchEnabled(): boolean {
    return window.matchMedia('(pointer: coarse)').matches;
  }

  ngOnInit(): void {
    // Timer to update badge every seconds
    timer(0, 30000)
      .pipe(
        takeUntil(this._unsubscribeAll),
        switchMap(() => this.currentUserService.refreshUserBadgeInfo()),
      )
      .subscribe();

    this.currentUserService
      .currentUser()
      .pipe(
        filter((value) => value !== null),
        takeUntil(this._unsubscribeAll),
      )
      .subscribe((user) => {
        this.user = user;
        this.updateMenuBasedOnUser(user);
        this.updateBadges(this.user.userSettings);
      });

    // Closes sidebar after clicking a link (on mobile)
    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this._unsubscribeAll),
      )
      .subscribe(() => {
        if (this._fuseSidebarService.getSidebar('navbar')) {
          this._fuseSidebarService.getSidebar('navbar').close();
        }
      });

    // Subscribe to the config changes
    this._fuseConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe((config) => {
      this.fuseConfig = config;
    });

    // Get current navigation
    this._fuseNavigationService.onNavigationChanged
      .pipe(
        filter((value) => value !== null),
        takeUntil(this._unsubscribeAll),
      )
      .subscribe(() => {
        for (const key of Object.keys(this.features)) {
          this._fuseNavigationService.updateNavigationItem(key.toLowerCase(), {
            hidden: this.features[key] === 'disabled',
          });
        }
        this.navigation = this._fuseNavigationService.getCurrentNavigation();
        this.navbar = this._fuseSidebarService.getSidebar('navbar');
      });
    setTimeout(() => {
      /** Call resize event manually to change menu visibility **/
      window.dispatchEvent(new Event('resize'));
    });
  }

  ngAfterViewInit(): void {}

  updateBadges(userSettings): void {
    if (userSettings.pendingQuotations) {
      this._fuseNavigationService.updateNavigationItem('offers', {
        badge: { title: userSettings.pendingQuotations, bg: '#f3e41a', fg: '#000000' },
      });
    }
    if (userSettings.pendingOrders) {
      this._fuseNavigationService.updateNavigationItem('orders', {
        badge: { title: userSettings.pendingOrders, bg: '#f3e41a', fg: '#000000' },
      });
    }
  }

  closeIfMenuMode(): void {
    if (this.menuMode) {
      this._fuseSidebarService.getSidebar('navbar').close(true);
    }
  }

  updateMenuBasedOnUser(user: User): void {
    // These are all separate updates. Could this be optimized?
    /*** Hide all non-all-user items by default **/

    this._fuseNavigationService.updateNavigationItem('enquiries_create', { hidden: true });
    this._fuseNavigationService.updateNavigationItem('enquiries_list', { hidden: true });
    this._fuseNavigationService.updateNavigationItem('offers', { hidden: true });
    this._fuseNavigationService.updateNavigationItem('orders', { hidden: true });
    this._fuseNavigationService.updateNavigationItem('aircrafts', { hidden: true });
    this._fuseNavigationService.updateNavigationItem('airportGroups', { hidden: true });
    this._fuseNavigationService.updateNavigationItem('aircraft-type-management', { hidden: true });
    this._fuseNavigationService.updateNavigationItem('crew', { hidden: true });
    this._fuseNavigationService.updateNavigationItem('catering', { hidden: true });
    this._fuseNavigationService.updateNavigationItem('acmi', { hidden: true });
    this._fuseNavigationService.updateNavigationItem('pooling', { hidden: true });
    this._fuseNavigationService.updateNavigationItem('client-admin', { hidden: true });
    this._fuseNavigationService.updateNavigationItem('admin-reports', { hidden: true });
    this._fuseNavigationService.updateNavigationItem('airline-admin', { hidden: true });
    this._fuseNavigationService.updateNavigationItem('client-settings-section', { hidden: true });
    this._fuseNavigationService.updateNavigationItem('airline-settings-section', { hidden: true });
    this._fuseNavigationService.updateNavigationItem('root-settings-section', { hidden: true });

    switch (user.role) {
      case 'airline-admin':
        this._fuseNavigationService.updateNavigationItem('enquiries_create', { hidden: true });
        this._fuseNavigationService.updateNavigationItem('offers', { hidden: false });
        this._fuseNavigationService.updateNavigationItem('orders', { hidden: false });
        this._fuseNavigationService.updateNavigationItem('aircrafts', { hidden: false });
        this._fuseNavigationService.updateNavigationItem('crew', { hidden: false });
        this._fuseNavigationService.updateNavigationItem('catering', { hidden: false });
        this._fuseNavigationService.updateNavigationItem('pooling', { hidden: true });
        this._fuseNavigationService.updateNavigationItem('acmi', { hidden: false });
        this._fuseNavigationService.updateNavigationItem('airportGroups', { hidden: true }); // <-- TO DO CHANGE TO FALSE WHEN DONE
        this._fuseNavigationService.updateNavigationItem('airline-settings-section', {
          hidden: false,
        });
        break;

      case 'airline-user':
        this._fuseNavigationService.updateNavigationItem('enquiries_create', { hidden: true });
        this._fuseNavigationService.updateNavigationItem('offers', { hidden: false });
        this._fuseNavigationService.updateNavigationItem('orders', { hidden: false });
        this._fuseNavigationService.updateNavigationItem('aircrafts', { hidden: true });
        this._fuseNavigationService.updateNavigationItem('crew', { hidden: false });
        this._fuseNavigationService.updateNavigationItem('catering', { hidden: false });
        this._fuseNavigationService.updateNavigationItem('acmi', { hidden: false });
        this._fuseNavigationService.updateNavigationItem('pooling', { hidden: true });
        break;

      case 'client-admin':
        this._fuseNavigationService.updateNavigationItem('enquiries_create', { hidden: false });
        this._fuseNavigationService.updateNavigationItem('enquiries_list', { hidden: false });
        this._fuseNavigationService.updateNavigationItem('offers', { hidden: false });
        this._fuseNavigationService.updateNavigationItem('orders', { hidden: false });
        this._fuseNavigationService.updateNavigationItem('aircrafts', { hidden: true });
        this._fuseNavigationService.updateNavigationItem('crew', { hidden: true });
        this._fuseNavigationService.updateNavigationItem('catering', { hidden: true });
        this._fuseNavigationService.updateNavigationItem('pooling', { hidden: false });
        this._fuseNavigationService.updateNavigationItem('client-settings-section', {
          hidden: false,
        });
        break;

      case 'client-user':
        this._fuseNavigationService.updateNavigationItem('enquiries_create', { hidden: false });
        this._fuseNavigationService.updateNavigationItem('offers', { hidden: false });
        this._fuseNavigationService.updateNavigationItem('enquiries_list', { hidden: false });
        this._fuseNavigationService.updateNavigationItem('orders', { hidden: false });
        this._fuseNavigationService.updateNavigationItem('aircrafts', { hidden: true });
        this._fuseNavigationService.updateNavigationItem('crew', { hidden: true });
        this._fuseNavigationService.updateNavigationItem('catering', { hidden: true });
        this._fuseNavigationService.updateNavigationItem('pooling', { hidden: false });
        break;

      case 'root-admin':
        this._fuseNavigationService.updateNavigationItem('enquiries_create', { hidden: false });
        this._fuseNavigationService.updateNavigationItem('enquiries_list', { hidden: false });
        this._fuseNavigationService.updateNavigationItem('offers', { hidden: false });
        this._fuseNavigationService.updateNavigationItem('orders', { hidden: false });
        this._fuseNavigationService.updateNavigationItem('aircrafts', { hidden: false });
        this._fuseNavigationService.updateNavigationItem('airportGroups', { hidden: false });
        this._fuseNavigationService.updateNavigationItem('aircraft-type-management', {
          hidden: false,
        });
        this._fuseNavigationService.updateNavigationItem('crew', { hidden: true });
        this._fuseNavigationService.updateNavigationItem('catering', { hidden: true });
        this._fuseNavigationService.updateNavigationItem('pooling', { hidden: true });
        this._fuseNavigationService.updateNavigationItem('acmi', { hidden: false });
        this._fuseNavigationService.updateNavigationItem('client-admin', { hidden: false });
        this._fuseNavigationService.updateNavigationItem('airline-admin', { hidden: false });
        this._fuseNavigationService.updateNavigationItem('admin-reports', { hidden: false });
        this._fuseNavigationService.updateNavigationItem('root-settings-section', {
          hidden: false,
        });
        break;

      default:
        console.error(`No such user role: ${user.role} - LOGGING OUT INVALID USER`);
        this.authenticationService.signOut();
        break;
    }

    this.updateBadges(user.userSettings);
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
